import React from 'react';

const TermsOfUse = () => {
    return (
        <div className="mx-auto max-w-6xl mt-20">
            <h1 className="font-semibold text-3xl text-secondary">
                Terms of Use
            </h1>
            <p className="text-secondary mt-4">
                Tk.
            </p>
        </div>
    );
};

export default TermsOfUse;
